const env = {
  apiHost: "http://localhost:8085/",
  // apiHost: "https://tictactrump.io:8085/",
  TTTusdtprice: 0.0250,
  // testnet
  tokenAddres: "0xA619B3a95BE94cC955F7A032009D53f2562bD3bE",
  percentage: 1,
  usdtAddres: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  BuyAddress: "0xb7f08b91a52dc7dd0afc2729553d754d0727a2b7",
  VestingAddress: "0x6ea3b1f8e9321a8ba7047912dd0fb8acad979303",
  rpc_url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  explorerUrl: "https://testnet.bscscan.com/",

  // mainnet
  // tokenAddres: "0x74F6D06141F6E1eDE81bC9047e35E82EdffC666D",
  // percentage: 1,
  // usdtAddres: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  // wethAddres: "0x4200000000000000000000000000000000000006",

  // BuyAddress: "0xaeb89d4d227241aC45B51A18532C72Ad614FA8C8",
  // rpc_url: "https://base.llamarpc.com",
  // explorerUrl: "https://basescan.org",
};


export default env;  