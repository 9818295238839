export const assetList = [
  {
    id: 1,
    allocations: "Team",
    tokenPercentage: "18.00%",
    tokenAllocation: "270,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 6,750,000.00",
    cliffPeriod: "12 months",
    vestingPeriod: "36 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "270,000,000",
  },
  {
    id: 2,
    allocations: "Advisors and Partners",
    tokenPercentage: "1.00%",
    tokenAllocation: "15,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 375,000.0000",
    cliffPeriod: "12 months",
    vestingPeriod: "24 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "15,000,000",
  },
  {
    id: 3,
    allocations: "Ecosystem development",
    tokenPercentage: "20.00%",
    tokenAllocation: "300,000,000",
    amountToRaise: "$ 7,500,000.00",
    tokenprice: "$ 0.0250",
    cliffPeriod: "12 months",
    vestingPeriod: "36 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "5.00%",
    unlockHash: "15,000,000",
    unlockVest: "285,000,000",
  },
  {
    id: 4,
    allocations: "Airdrops",
    tokenPercentage: "3.00%",
    tokenAllocation: "45,000,000",
    tokenprice: "$ 0.0250",
    cliffPeriod: "12 months",
    vestingPeriod: "0 months",
    amountToRaise: "$ 1,125,000.00 ",
    total: "19/07/24 08:42 pm",
    unlockPer: "30.00%",
    unlockHash: "13,500,000",
    unlockVest: "31,500,000",
  },
  {
    id: 5,
    allocations: "Marketing",
    tokenPercentage: "10.00%",
    tokenAllocation: "150,000,000",
    amountToRaise: "$ 3,750,000.00",
    tokenprice: "$ 0.0250",
    cliffPeriod: "12 months",
    vestingPeriod: "36 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "2.00%",
    unlockHash: "3,000,000 ",
    unlockVest: "147,000,000",
  },
  // {
  //   id: 6,
  //   allocations: "Ambassadors",
  //   tokenPercentage: "0.00%",
  //   tokenAllocation: "0",
  //   tokenprice: "$ 0.0250",
  //   amountToRaise: "-",
  //   cliffPeriod: "0 months",
  //   vestingPeriod: "0 months",
  //   total: "19/07/24 08:42 pm",
  //   unlockPer: "0.00%",
  //   unlockHash: "0",
  //   unlockVest: "0",
  // },
  {
    id: 6,
    allocations: "Ecosystem rewards",
    tokenPercentage: "3.00%",
    tokenAllocation: "45,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 1,125,000.00",
    cliffPeriod: "0 months",
    vestingPeriod: "48 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "2.08%",
    unlockHash: "  937,500 ",
    unlockVest: "44,062,500",
  },
  {
    id: 7,
    allocations: "Staking rewards",
    tokenPercentage: "7.00%",
    tokenAllocation: "105,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 2,625,000.00",
    cliffPeriod: "6 months",
    vestingPeriod: "48 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "105,000,000",
  },
  {
    id: 8,
    allocations: "Treasury",
    tokenPercentage: "15.00%",
    tokenAllocation: "225,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 5,625,000.00",
    cliffPeriod: "0 months",
    vestingPeriod: "48 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "2.00%",
    unlockHash: "4,500,000",
    unlockVest: "220,500,000",
  },
  {
    id: 9,
    allocations: "Liquidity / Exchanges",
    tokenPercentage: "15.00%",
    tokenAllocation: "225,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 5,625,000.00",
    cliffPeriod: "0 months",
    vestingPeriod: "48 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "1.00%",
    unlockHash: "2,250,000",
    unlockVest: "222,750,000",
  },
  {
    id: 10,
    allocations: "Seed round",
    tokenPercentage: "4.00%",
    tokenAllocation: "60,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 1,500,000.00",
    cliffPeriod: "6 months",
    vestingPeriod: "24 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "60,000,000",
  },
  {
    id: 11,
    allocations: "Private round",
    tokenPercentage: "2.00%",
    tokenAllocation: "30,000,000",
    tokenprice: "$ 0.0150",
    amountToRaise: "$ 450,000.00",
    cliffPeriod: "0 months",
    vestingPeriod: "12 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "5.00%",
    unlockHash: "1,500,000",
    unlockVest: "28,500,000",
  },
  {
    id: 12,
    allocations: "Public round",
    tokenPercentage: "2.00%",
    tokenAllocation: "30,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 750,000.00",
    cliffPeriod: "0 months",
    vestingPeriod: "12 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "20.00%",
    unlockHash: "6,000,000",
    unlockVest: "24,000,000",
  },
];
