import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Landing from "./pages/Landing";
import "./App.css";
import Landing from "./Pages/Landing";
import PDFViewer from "./Pages/PDFViewer";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const projectId = "0b8444fa79de9a3821c5410564de263f";

const mainnet = {
  // chainId: 0x38,
  // name: "Binance Smart Chain",
  // currency: "BNB",
  // explorerUrl: "https://bscscan.com/",
  // rpcUrl: "https://bsc-dataseed1.binance.org/",

  chainId: 97,
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  name: "Binance Smart Chain Testnet",
  currency: "tBNB",
};

const chains = [mainnet];

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Solana Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.mywebsite.com/"],
};

const ethersConfig = defaultConfig({
  metadata,
  chains,
  projectId,
  coinbasePreference: "eoaOnly",
  auth: {
    email: false, // default to true
    // socials: ['google', 'x', 'github', 'discord', 'apple'],
    showWallets: true, // default to true
    walletFeatures: true, // default to true
  },
});

createWeb3Modal({
  ethersConfig,
  chains,
  projectId,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
});

function App() {

  useEffect(() => {
    // Initialize Web3Modal once when the app starts
    createWeb3Modal({
      ethersConfig,
      chains,
      projectId,
      includeWalletIds: [
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
      ],
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/whitepaper" element={<PDFViewer />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
