import WHITEPAPER from "../Assets/whitepaper.pdf";

function PDFViewer() {
  return (
    <iframe
      src={WHITEPAPER}
      style={{ width: "100%", height: "100vh" }}
      title="PDF Viewer"
    ></iframe>
  );
}

export default PDFViewer;
