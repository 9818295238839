import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import MenuIcon from "@material-ui/icons/Menu";
import { ethers } from "ethers";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { assetList } from "../utils/mockdata";
import Slider from "react-slick";
import AOS from "aos";
import axios from "axios";
import "aos/dist/aos.css";
import USDTbalanceABI from "./USDCABI.json"; //mainnet
// import USDTbalanceABI from "./CainLink.json"; //Testnet
import TokenABI from "./TokenABI.json";
import SwapABI from "./Swap.json";
import nextArrowImg from "../Assets/compress/right-arr.webp";
import prevArrowImg from "../Assets/compress/left-arr.webp";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { toast } from "react-toastify";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";

import BNBforTokenABI from "./BNBforTokenABI.json";
import env from "../core/service/envconfig";
import VestingABI from "./VestingABI.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

const Landing = () => {
  const sectionRef = useRef(null);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [dropstatus, setdropstatus, dropstatusref] = useState(false);
  const [buyStatus, setbuyStatus, buyStatusref] = useState(true);

  const dropdowns = async () => {
    console.log(dropstatusref.current, "dropstatus");
    if (dropstatusref.current == true) {
      setdropstatus(false);
    } else {
      setdropstatus(true);
    }
  };

  const NextArrow = (props) => {
    const { className, style, onClick, currentSlide, slideCount } = props;

    const isDisabled = currentSlide >= 4;

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundImage: `url(${nextArrowImg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: "30px",
          height: "30px",
          opacity: isDisabled ? "0.5" : "1",
          cursor: isDisabled ? "not-allowed" : "pointer",
          transition: "opacity 0.3s ease",
        }}
        onClick={!isDisabled ? onClick : null}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;

    const isDisabled = currentSlide === 0;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundImage: `url(${prevArrowImg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: "30px",
          height: "30px",
          opacity: isDisabled ? "0.5" : "1", // Make the arrow semi-transparent if inactive
          cursor: isDisabled ? "not-allowed" : "pointer", // Change the cursor if inactive
          transition: "opacity 0.3s ease",
        }}
        onClick={!isDisabled ? onClick : null}
      />
    );
  };

  const cancelPageChange = (event, pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
  };

  useEffect(() => {
    AOS.init();

    // Function to handle AOS attributes removal
    const handleAOS = () => {
      if (window.innerWidth < 600) {
        document.querySelectorAll("[data-aos]").forEach((el) => {
          el.setAttribute("data-aos", "");
          el.setAttribute("data-aos-delay", "");
          el.setAttribute("data-aos-duration", "");
        });
      } else {
        document.querySelectorAll(".aos-element").forEach((el) => {
          el.setAttribute("data-aos", "fade-right");
          el.setAttribute("data-aos-delay", "900");
        });
        AOS.refresh();
      }
    };

    // Run the function on initial load
    handleAOS();

    // Add event listener for window resize
    window.addEventListener("resize", handleAOS);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleAOS);
    };
  }, [0]);

  const navigate = useNavigate();
  const classes = useStyles();

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const nav_page = () => {
    navigate("/register");
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef(null);

  const phases = ["Phase 1", "Phase 2", "Phase 3", "Phase 4", "Phase 5"];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
    ref: sliderRef, // Update currentSlide after each change
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const section = sectionRef.current;

    if (section) {
      const rectangles = section.querySelectorAll(".rectangle");

      const options = {
        root: null, // viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the section is in view
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            rectangles.forEach((rectangle) => {
              // Remove the animation class to reset the animation
              rectangle.classList.remove("animate");

              // Trigger reflow to restart the animation
              void rectangle.offsetWidth;

              // Add the animation class again to trigger the animation
              rectangle.classList.add("animate");
            });
          }
        });
      }, options);

      observer.observe(section);

      return () => observer.disconnect(); // Cleanup the observer on unmount
    }
  }, [0]);

  const handlePhaseClick = (index) => {
    setCurrentSlide(index); // Update the current slide state
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index); // Navigate to the specific slide
    }
  };

  // const projectId = "0b8444fa79de9a3821c5410564de263f";

  // const mainnet = {
  //   // chainId: 0x38,
  //   // name: "Binance Smart Chain",
  //   // currency: "BNB",
  //   // explorerUrl: "https://bscscan.com/",
  //   // rpcUrl: "https://bsc-dataseed1.binance.org/",

  //   chainId: 97,
  //   explorerUrl: "https://testnet.bscscan.com/",
  //   rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  //   name: "Binance Smart Chain Testnet",
  //   currency: "tBNB",
  // };

  // const chains = [mainnet];

  // const metadata = {
  //   name: "Web3Modal",
  //   description: "Web3Modal Solana Example",
  //   url: "https://web3modal.com",
  //   icons: ["https://avatars.mywebsite.com/"],
  // };

  // const ethersConfig = defaultConfig({
  //   metadata,
  //   chains,
  //   projectId,
  //   coinbasePreference: "eoaOnly",
  //   auth: {
  //     email: false, // default to true
  //     // socials: ['google', 'x', 'github', 'discord', 'apple'],
  //     showWallets: true, // default to true
  //     walletFeatures: true, // default to true
  //   },
  // });

  // createWeb3Modal({
  //   ethersConfig,
  //   chains,
  //   projectId,
  //   includeWalletIds: [
  //     "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  //     "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  //   ],
  // });

  //DRAWER ================================

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, isConnected } = useWeb3ModalAccount();
  const [address, setaddress] = useState("");
  const [bnbAmount, setBnbAmount] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [USDTBalance, setUSDTBalance, USDTBalanceref] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("BNB");
  const [tokenBalance, setTokenBalance, tokenBalanceref] = useState(0);
  const [UsdcBalance, setUsdcBalance, UsdcBalanceref] = useState(0);

  const [scknAmount, setscknAmount] = useState(0);
  const [usdtPrice, setusdtPrice] = useState(0);
  const [BNBprice, setBNBprice] = useState(0);

  const [offset, setOffset] = useState(0);
  const buyChickenRef = useRef(null);

  const handleWalletConnect = async () => {
    await open(); // Open the modal
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   AOS.init();

  //   // Function to handle AOS attributes removal
  //   const handleAOS = () => {
  //     if (window.innerWidth < 600) {
  //       document.querySelectorAll("[data-aos]").forEach((el) => {
  //         el.setAttribute("data-aos", "");
  //         el.setAttribute("data-aos-delay", "");
  //         el.setAttribute("data-aos-duration", "");
  //       });
  //     } else {
  //       document.querySelectorAll(".aos-element").forEach((el) => {
  //         el.setAttribute("data-aos", "fade-right");
  //         el.setAttribute("data-aos-delay", "900");
  //       });
  //       AOS.refresh();
  //     }
  //   };

  //   // Run the function on initial load
  //   handleAOS();

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleAOS);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleAOS);
  //   };
  // }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getbalanceaddress = async () => {
    try {
      Loading.pulse("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#733bf5",
        svgSize: "100px",
        backgroundColor: "rgb(0 0 0 / 87%)",
      });
      try {
        if (!isConnected) throw Error("User disconnected");

        //console.log("inside");

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        //console.log("address", address);
        setaddress(address);
        //console.log(address, "address");

        localStorage.setItem("userAddress", address);
        toast.success("Wallet Connected!", {
          toastId: "success1",
        });

        FetchBNBbalnce(address);
        FetchZurumibalnce(address);
        FetchUSDTbalance(address);
        initializeWeb3();
        Loading.remove();
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  };

  async function FetchZurumibalnce(data) {
    if (data) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const Tokencontarct = new Contract(
        env.tokenAddres,
        TokenABI,
        bnbProvider
      );

      console.log(data, "data");
      const tokenBalance = await Tokencontarct.balanceOf(data);

      console.log(tokenBalance, "tokenBalance");
      localStorage.setItem(
        "scknBalance",
        Number(tokenBalance) / 1000000000000000000
      );
      setTokenBalance(Number(tokenBalance) / 1000000000000000000);

      console.log(tokenBalance, "tokenBalanceref", tokenBalanceref.current);
    } else {
      //console.log("invaid addeesssssjjdnnd");
    }
  }

  async function FetchBNBbalnce(data) {
    const ethBalance1 = localStorage.getItem("Balance");
    setUSDTBalance(ethBalance1);

    if (data) {
      if (walletProvider != null) {
        const provider = new BrowserProvider(walletProvider);

        const balance = await provider.getBalance(data);

        const ethBalance = parseInt(balance) / 1000000000000000000;
        localStorage.setItem("Balance", ethBalance);
        setUSDTBalance(ethBalance);

        console.log(ethBalance, "ethBalanceref");
      }
    } else {
    }
  }

  async function FetchUSDTbalance(data) {
    if (data) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const Tokencontarct = new Contract(
        env.usdtAddres,
        USDTbalanceABI,
        bnbProvider
      );
      const tokenBalance = await Tokencontarct.balanceOf(data);
      localStorage.setItem(
        "USDTBalance",
        Number(tokenBalance) / 1000000000000000000
      );
      setUsdcBalance(Number(tokenBalance) / 1000000000000000000);

      console.log(tokenBalance, "tokenBalanceref", tokenBalanceref.current);
    } else {
      console.log("invaid addeesssssjjdnnd");
    }
  }

  if (isConnected) {
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }

  async function disconnectWallet() {
    Loading.pulse("Disconnecting...", {
      messageColor: "#733bf5",
      svgColor: "#733bf5",
      svgSize: "100px",
      backgroundColor: "rgb(0 0 0 / 87%)",
    });
    localStorage.clear();
    const provider = new BrowserProvider(walletProvider);
    // console.log("Provider:", provider);
    // console.log("Provider emit method:", provider?.emit);
    try {
      if (provider && typeof provider?.emit === "function") {
        await disconnect();
        toast.error("Wallet Disconnected!", {
          toastId: "error1",
        });
      } else {
        //console.error("Provider or emit function is not available.");
      }
    } catch (error) {
      //console.error("Error occurred while disconnecting:", error);
    }
    setaddress("");
    setAnchorEl(null);
    initializeWeb3();
    Loading.remove();
  }

  async function initializeWeb3() {
    try {
      const userAddress = localStorage.getItem("userAddress");
      setaddress(userAddress);

      if (userAddress == null) {
        setTokenBalance(0);
        setUSDTBalance(0);
        setUsdcBalance(0);

        setaddress("");
      } else {
        //console.log(userAddress);

        FetchZurumibalnce(userAddress);
        FetchUSDTbalance(userAddress);

        FetchBNBbalnce(userAddress);
      }
    } catch (error) {
      //console.error("Error occurred while disconnecting:", error);
    }
  }

  useEffect(() => {
    initializeWeb3();
  }, [0]);

  const formatter_interest = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  });

  useEffect(() => {
    const getUsdtPrice = async () => {
      Loading.pulse("Processing...", {
        messageColor: "#733bf5",
        svgColor: "#733bf5",
        svgSize: "100px",
        backgroundColor: "rgb(0 0 0 / 87%)",
      });
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=BNB&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      const apiUrl1 = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USDC&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;

      const response = await axios.get(apiUrl);
      const response1 = await axios.get(apiUrl1);

      if (
        response.data &&
        response.data.BNB &&
        response1.data &&
        response1.data.USDC
      ) {
        console.log(response, response1);

        const value = response.data.BNB.USDT;
        const value1 = response1.data.USDC.USDT;

        let unitCalculation;

        if (selectedCurrency === "BNB") {
          unitCalculation = (value * 1) / +env.TTTusdtprice;
          setBNBprice(value.toFixed(4));
          setusdtPrice(unitCalculation.toFixed(4));
        } else {
          unitCalculation = (value1 * 1) / +env.TTTusdtprice;
          setBNBprice(value1.toFixed(4));
          setusdtPrice(unitCalculation.toFixed(4));
        }
      }
      Loading.remove();
    };

    getUsdtPrice();
  }, [selectedCurrency]);

  useEffect(() => {
    const calculatedTttAmount = bnbAmount * usdtPrice;
    setscknAmount(calculatedTttAmount.toFixed(2));
  }, [bnbAmount, usdtPrice]);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    checkBuy();
  }, [0]);

  useEffect(() => {
    const targetDateUTC = new Date("2024-11-15T00:00:00Z"); // 00:00:00 EST is 04:00:00 UTC

    const updateTimer = () => {
      const nowUTC = new Date();
      const difference = targetDateUTC - nowUTC;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };
    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, []);

  const checkBuy = async () => {
    try {
      const provider = new BrowserProvider(walletProvider);

      const signer = await provider.getSigner();

      const vestingContract = new Contract(
        env.VestingAddress,
        VestingABI,
        signer
      );
      // check vesting available
      let available_sale = await vestingContract.availableForSale("publicSale");
      console.log("available_sale privateSale", available_sale);
      const availableSale = parseInt(available_sale) / 1000000000000000000;
      console.log("availableSale privateSale 11", availableSale);
      if (+availableSale > 0) {
        setbuyStatus(true);
      } else {
        setbuyStatus(false);
      }
    } catch (error) {}
  };

  const handleBuy = async () => {
    Loading.pulse("Processing...", {
      messageColor: "#733bf5",
      svgColor: "#733bf5",
      svgSize: "100px",
      backgroundColor: "rgb(0 0 0 / 87%)",
    });

    console.log(selectedCurrency, "selectedCurrency");

    try {
      let Buy_approve;
      let Buy;
      let Approved_one;

      if (scknAmount >= 1) {
        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const provider = new BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        // console.log(signer, "signers");

        const tokenContract_usdt = new Contract(
          env.usdtAddres,
          USDTbalanceABI,
          signer
        );

        const swapContract1 = new Contract(env.BuyAddress, SwapABI, signer);

        const vestingContract = new Contract(
          env.VestingAddress,
          VestingABI,
          signer
        );
        // check vesting available
        let available_sale = await vestingContract.availableForSale(
          "publicSale"
        );
        console.log("available_sale", available_sale);
        const availableSale = parseInt(available_sale) / 1000000000000000000;
        console.log("availableSale", availableSale);
        if (+availableSale == 0) {
          toast.error("No Tokens are Available", {
            toastId: "error2",
          });
        } else {
          if (selectedCurrency == "BNB") {
            if (
              USDTBalanceref.current < 0 ||
              bnbAmount > USDTBalanceref.current
            ) {
              toast.error("Insufficient BNB Balance", {
                toastId: "error3",
              });
              setBnbAmount(0);
              setscknAmount(0);
              Loading.remove();
            } else {
              const weiValue = ethers.parseUnits(bnbAmount.toString(), 18);

              const receiveamount = ethers.parseUnits(
                scknAmount.toString(),
                18
              );

              const gasPrice = (await provider.getFeeData()).gasPrice;

              try {
                Buy_approve = await swapContract1.buyToken(weiValue, {
                  value: weiValue,
                });

                await Buy_approve.wait();

                if (Buy_approve) {
                  Buy = await vestingContract.sellTokens(
                    "publicSale",
                    receiveamount
                  );

                  await Buy.wait();
                }

                FetchZurumibalnce(address);
                FetchUSDTbalance(address);

                FetchBNBbalnce(address);

                if (Buy) {
                  toast.success("Token Purcashed Sucessfully", {
                    toastId: "success2",
                  });
                  // window.location.reload();
                  initializeWeb3();
                  setBnbAmount("");
                  Loading.remove();
                }
              } catch (error) {
                console.log("error", error);
                Loading.remove();
                FetchZurumibalnce(address);
                setBnbAmount(0);
                setscknAmount(0);
                FetchUSDTbalance(address);
                FetchBNBbalnce(address);
                toast.error("Transaction failed", {
                  toastId: "error4",
                });
              }
            }
          } else {
            console.log("inside the USDT");
            if (
              UsdcBalanceref.current < 0 ||
              bnbAmount > UsdcBalanceref.current
            ) {
              toast.error("Insufficient USDT Balance", {
                toastId: "error5",
              });
              setBnbAmount(0);
              setscknAmount(0);
              Loading.remove();
            } else {
              console.log("inside the USDT");

              const weiValue = ethers.parseUnits(bnbAmount.toString(), 18);

              const receiveamount = ethers.parseUnits(
                scknAmount.toString(),
                18
              );

              console.log(receiveamount, "receiveamount");

              var Approved = await tokenContract_usdt.approve(
                env.BuyAddress,
                weiValue
              );

              await Approved.wait();

              console.log(Approved, "Approved");

              console.log("inside the USDT approved");

              if (Approved) {
                try {
                  Buy_approve = await swapContract1.buy_usdt(
                    weiValue,
                    env.usdtAddres
                  );

                  await Buy_approve.wait();

                  if (Buy_approve) {
                    Buy = await vestingContract.sellTokens(
                      "publicSale",
                      receiveamount
                    );
                    await Buy.wait();
                  }

                  FetchZurumibalnce(address);
                  FetchUSDTbalance(address);

                  FetchBNBbalnce(address);

                  if (Buy) {
                    toast.success("Token Purcashed Sucessfully", {
                      toastId: "success3",
                    });
                    // window.location.reload();
                    initializeWeb3();
                    setBnbAmount("");
                    Loading.remove();
                  }
                } catch (error) {
                  Loading.remove();
                  FetchZurumibalnce(address);
                  FetchUSDTbalance(address);
                  FetchBNBbalnce(address);
                  setBnbAmount(0);
                  setscknAmount(0);
                  console.log(error, "error");
                  toast.error("Transaction failed", {
                    toastId: "error7",
                  });
                }
              } else {
                Loading.remove();
              }
            }
          }
        }
      } else {
        Loading.remove();

        toast.error("Buy Minimum 1 ZRM Token", {
          toastId: "error8",
        });
      }
    } catch (error) {
      toast.error("Transaction failed", {
        toastId: "error9",
      });
      Loading.remove();
      setBnbAmount(0);
      setscknAmount(0);
      console.log("eror", error);
      FetchZurumibalnce(address);
      FetchUSDTbalance(address);

      FetchBNBbalnce(address);
    }
  };

  const selectToken = (value) => {
    setSelectedCurrency(value);
  };

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setAllCurrency(currArrayCrypto);
    setSelectedCurrency(currArrayCrypto[0].value);
  }, [0]);

  return (
    <>
      <main className="zurumi_main_page">
        {/* Navbar */}
        <header className="">
          <div className="scrolled">
            <div className="header_new_mega">
              <div className={`${classes.root} `}>
                <AppBar position="static">
                  <Toolbar className="container header-wrapper">
                    <Typography variant="h6" className={classes.title}>
                      <div className="logo_new_sectio d-flex">
                        <a href="/" className="logo-brand">
                          <img
                            src={require("../../src/Assets/compress/zurumi_logo.webp")}
                            className="img-fluid "
                            alt="logo"
                          />
                        </a>
                        <div className={`menu_new_typr ${classes.appBarItems}`}>
                          <a
                            href="#about_zurumi"
                            color="inherit"
                            className="contact_button"
                          >
                            About Zurumi
                          </a>
                          <a
                            href="#zurumi_tokenomics"
                            color="inherit"
                            className="contact_button"
                          >
                            Tokenomics
                          </a>{" "}
                          <a
                            href="#roadmap_zurumi"
                            color="inherit"
                            className="contact_button"
                          >
                            Roadmap
                          </a>
                          <a
                            href="#presale_zurumi"
                            color="inherit"
                            className="contact_button"
                          >
                            Presale
                          </a>{" "}
                          <a
                            href="/whitepaper"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="inherit"
                            className="contact_button"
                          >
                            Whitepaper
                          </a>
                        </div>
                      </div>
                    </Typography>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                    >
                      <MenuIcon onClick={handleMobileMenuOpen} />
                    </IconButton>
                    <div className={`menu_new_typr ${classes.appBarItems}`}>
                      {address == "" ? (
                        <Button
                          className="started_btn"
                          onClick={handleWalletConnect}
                        >
                          Connect Wallet
                        </Button>
                      ) : (
                        <>
                          <Button className="started_btn" onClick={dropdowns}>
                            {address.substring(0, 12)}...
                          </Button>
                          <div
                            className={
                              dropstatusref.current == true
                                ? "dropdown-content"
                                : "dropdown-content d-none"
                            }
                            onClick={() => disconnectWallet()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              id="logout"
                            >
                              <path
                                d="m366.863 323.883 22.627 22.627L480 256l-90.51-90.51-22.628 22.628L418.745 240H192v32h226.745z"
                                fill="#0a0914"
                                class="color000000 svgShape"
                              ></path>
                              <path
                                d="M391.491 391.766C355.229 428.029 307.018 448 255.736 448c-51.287 0-99.506-19.971-135.772-56.235C83.697 355.501 64 307.285 64 256c0-51.281 19.697-99.495 55.965-135.761C156.232 83.973 204.45 64 255.736 64c51.279 0 99.491 19.973 135.755 56.238a196.044 196.044 0 0 1 7.333 7.762h40.731c-40.474-58.028-107.709-96-183.819-96C132.021 32 32 132.298 32 256c0 123.715 100.021 224 223.736 224 76.112 0 143.35-37.97 183.822-96h-40.73a194.792 194.792 0 0 1-7.337 7.766z"
                                fill="#0a0914"
                                class="color000000 svgShape"
                              ></path>
                            </svg>
                            <span>Disconnect</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Toolbar>
                </AppBar>

                <Drawer
                  anchor="left"
                  open={mobileMenuOpen}
                  className={classes.drawer}
                  onClose={() => handleMobileMenuClose(false)}
                >
                  <div className={classes.mobileMenu}>
                    <div className="drawer-logo-wrapper logo_new_sectio mobile_menu_icon">
                      <a
                        href="#buyzrm"
                        className="logo-brand"
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <img
                          src={require("../../src/Assets/compress/zurumi_logo.webp")}
                          className="img-fluid m-3 zurumi_logo"
                          alt="logo"
                        />
                      </a>
                    </div>
                    <div className="menu_statis">
                      <ListItem
                        button
                        className="drawa"
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <a href="#about_zurumi"> About Zurumi</a>
                      </ListItem>
                      <ListItem
                        button
                        className="drawa"
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <a href="#zurumi_tokenomics"> Tokenomics</a>
                      </ListItem>
                      <ListItem
                        button
                        className="drawa "
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <a href="#roadmap_zurumi">Roadmap</a>
                      </ListItem>

                      <ListItem
                        button
                        className="drawa "
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <a href="#presale_zurumi">Presale</a>
                      </ListItem>

                      <ListItem
                        button
                        className="drawa "
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        <a
                          href="/whitepaper"
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                          className="contact_button"
                        >
                          Whitepaper
                        </a>
                      </ListItem>

                      <ListItem
                        button
                        className="drawa "
                        onClick={() => handleMobileMenuClose(false)}
                      >
                        {address == "" ? (
                          <Button
                            className="started_btn"
                            onClick={handleWalletConnect}
                          >
                            Connect Wallet
                          </Button>
                        ) : (
                          <>
                            <Button className="started_btn" onClick={dropdowns}>
                              {address.substring(0, 10)}...
                            </Button>
                            <div
                              className={
                                dropstatus == true
                                  ? "dropdown-content"
                                  : "dropdown-content d-none"
                              }
                              onClick={() => disconnectWallet()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                id="logout"
                              >
                                <path
                                  d="m366.863 323.883 22.627 22.627L480 256l-90.51-90.51-22.628 22.628L418.745 240H192v32h226.745z"
                                  fill="#0a0914"
                                  class="color000000 svgShape"
                                ></path>
                                <path
                                  d="M391.491 391.766C355.229 428.029 307.018 448 255.736 448c-51.287 0-99.506-19.971-135.772-56.235C83.697 355.501 64 307.285 64 256c0-51.281 19.697-99.495 55.965-135.761C156.232 83.973 204.45 64 255.736 64c51.279 0 99.491 19.973 135.755 56.238a196.044 196.044 0 0 1 7.333 7.762h40.731c-40.474-58.028-107.709-96-183.819-96C132.021 32 32 132.298 32 256c0 123.715 100.021 224 223.736 224 76.112 0 143.35-37.97 183.822-96h-40.73a194.792 194.792 0 0 1-7.337 7.766z"
                                  fill="#0a0914"
                                  class="color000000 svgShape"
                                ></path>
                              </svg>
                              <span className="mx-1">Disconnect</span>
                            </div>
                          </>
                        )}
                      </ListItem>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </header>

        {/* Hero Section*/}
        <section className="zurumi_hero_section" id="buyzrm">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6"
                data-aos="fade-right"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000"
              >
                <div className="zurumi_hero_content">
                  <h1>Unveil the Power of the Next Breakthrough Token</h1>
                  <p>
                    Be early. Invest in the future with our groundbreaking
                    token.
                  </p>
                  {/* <button className="get_started_btn">Get Started</button> */}
                </div>
              </div>
              <div
                className="col-lg-6 buy_zurumi_padding"
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000"
              >
                <div className="buy_zurumi_card">
                  <div className="buy_zurumi_title">Buy ZURUMI Now!</div>

                  <div className="days_content">
                    <div className="days-circle-wrapper ">
                      <img
                        src={require("../Assets/compress/days_rounder.webp")}
                        className="days-circle-one"
                        alt="days-round"
                      />
                      <div className="days_text">
                        <h4>{timeLeft.days}</h4>
                        <p>{timeLeft.days == 1 ? "Day" : "Days"}</p>
                      </div>
                    </div>

                    <div className="days-circle-wrapper ">
                      <img
                        src={require("../Assets/compress/hrs_rounder.webp")}
                        // className="hrs_rounder "
                        className="days-circle-one"
                        alt="hrs-round"
                      />
                      <div className="days_text">
                        <h4>{timeLeft.hours}</h4>
                        <p>{timeLeft.hours == 1 ? "Hr" : "Hrs"}</p>
                      </div>
                    </div>

                    <div className="days-circle-wrapper ">
                      <img
                        src={require("../Assets/compress/mins_rounder.webp")}
                        // className="mins_rounder"
                        className="days-circle-one"
                        alt="min-round"
                      />
                      <div className="days_text">
                        <h4>{timeLeft.minutes}</h4>
                        <p>{timeLeft.minutes == 1 ? "Min" : "Mins"}</p>
                      </div>
                    </div>

                    <div className="days-circle-wrapper ">
                      <img
                        src={require("../Assets/compress/sec_rounder.webp")}
                        // className="sec_rounder"
                        className="days-circle-one"
                        alt="sec-round"
                      />
                      <div className="days_text">
                        <h4>{timeLeft.seconds}</h4>
                        <p>{timeLeft.seconds == 1 ? "Sec" : "Secs"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="select_coin_card">
                  <div className="select_coin_dropdown">
                    <h3>Select Coin</h3>
                    <div>
                      <Dropdown
                        inline
                        value={selectedCurrency}
                        options={allCurrency}
                        onChange={(e, { value }) => selectToken(value)}
                        icon="dropdown"
                        className="custom-dropdown"
                      />
                    </div>
                  </div>
                  <div className="pay_recive_content">
                    <div className="pay_recive_content_box">
                      <div className="pb-3 pay-receive-flex">
                        <h4>You Pay</h4>
                        <h6>
                          {selectedCurrency === "BNB" ? (
                            <>
                              Balance:{" "}
                              {formatter_interest.format(
                                USDTBalanceref.current
                              )}{" "}
                              BNB
                            </>
                          ) : (
                            <>
                              Balance:{" "}
                              {formatter_interest.format(
                                UsdcBalanceref.current
                              )}{" "}
                              USDT
                            </>
                          )}
                        </h6>
                      </div>

                      <div className="your_pay">
                        <div className="your_pay_coin">
                          {selectedCurrency == "BNB" ? (
                            <>
                              <img
                                src={
                                  "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png"
                                }
                                width="24px"
                                alt="bnb"
                              />
                              <p> BNB</p>
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png"
                                }
                                width="24px"
                                alt="usdt"
                              />
                              <p> USDT</p>
                            </>
                          )}
                        </div>
                        <div className="eth_amt">
                          <input
                            type="number"
                            min="0"
                            pattern="[0-9]*"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            value={bnbAmount}
                            placeholder="Enter Amount"
                            onChange={(e) => {
                              setBnbAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pay_recive_content_box">
                      <div className="d-flex pb-3 align-items-center justify-content-between pay-mt-3">
                        <h4>You Receive</h4>
                        <h6>
                          Balance:{" "}
                          {formatter_interest.format(tokenBalanceref.current)}{" "}
                          ZRM
                        </h6>
                      </div>
                      <div className="your_pay">
                        <div className="your_pay_coin">
                          <img
                            src={require("../Assets/compress/zrm_coin.webp")}
                            alt="zurumi-coin"
                          />
                          <p>ZRM</p>
                        </div>
                        <div className="eth_amt">
                          <input placeholder="0.00" value={scknAmount} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {address ? (
                    buyStatusref.current === true ? (
                      <button className="Connect_btn" onClick={handleBuy}>
                        Buy ZRM
                      </button>
                    ) : (
                      <button className="Connect_btn">Locked</button>
                    )
                  ) : (
                    <button
                      className="Connect_btn"
                      onClick={handleWalletConnect}
                    >
                      Connect Wallet
                    </button>
                  )}

                  <div className="select_coin_dropdown mt-3">
                    <h3 className="min-pad-2">
                      {" "}
                      1 {selectedCurrency} = {usdtPrice} ZRM (1 ZRM = ${" "}
                      {env.TTTusdtprice})
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section className="zurumi_about_section" id="about_zurumi">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right">
                <div>
                  <div className="about_bg">
                    <h4 className="about_zurumi_text">About ZURUMI</h4>
                    <p>
                      ZURUMI is a forward-thinking project aimed at transforming
                      the digital space by leveraging decentralized technology.
                      Our mission is to empower individuals and businesses with
                      innovative tools and opportunities, bridging the gap
                      between traditional industries and the future. Join us as
                      we create a secure, scalable, and user-friendly platform
                      for a connected tomorrow.
                    </p>
                    <div className="buy_zrm_button btn-bg-hidden">
                      {/*  */}
                      <a href="#buyzrm" className="buy_zrm-wrap">
                        <button className="buy_zrm">Buy $ZRM</button>
                      </a>
                    </div>
                    <div className="buy-zrm-about-img-wrap">
                      <img
                        src={require("../Assets/compress/about_icon.png")}
                        alt="zrm-about-icon"
                        className="left-about-icon"
                      />
                      <img
                        src={require("../Assets/compress/about_icon.png")}
                        alt="zrm-about-icon"
                        className="right-about-icon"
                      />
                    </div>
                    <div className="about__icon-wrapper">
                      <img
                        src={require("../Assets/compress/about_icon.png")}
                        alt="icon"
                        className="about__left-img"
                      />
                      <img
                        src={require("../Assets/compress/about_icon.png")}
                        alt="icon"
                        className="about__right-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 zurumi_about_banner"
                data-aos="fade-left"
              >
                <div className="about_banner">
                  <img
                    src={require("../Assets/compress/about_banner.webp")}
                    className="w-100"
                    alt="banner"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Tokenomics Section*/}
        <section className="zurumi_tokenomics_section" id="zurumi_tokenomics">
          <div className="container">
            <h2 className="zurumi_tokenomics_title" data-aos="fade-right">
              Tokenomics Of
              <br />
              Zurumi
            </h2>
            <div className="graph-holder" data-aos="fade-left">
              <div className="graph-main-box">
                <div className="graph-bottom-bg">
                  <img
                    src={require("../Assets/compress/graph_bottom_bg.webp")}
                    className="img-responsive"
                    alt="graph"
                  />
                  <div className="graph-text">
                    <div className="total_supply_text">Total Supply</div>
                    <br />
                    <strong>1,500,000,000 ZRM</strong>
                  </div>
                </div>
                <div className="graph-part part01">
                  <img
                    src={require("../Assets/compress/graph_part1.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info">
                    <div className="sale-info">Ecosystem development</div>
                    <strong>20%</strong>
                  </div>
                </div>
                <div className="graph-part part02">
                  <img
                    src={require("../Assets/compress/graph_part2.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-2">
                    <strong>3%</strong>
                    <div className="sale-info">Airdrops</div>
                  </div>
                </div>
                <div className="graph-part part03">
                  <img
                    src={require("../Assets/compress/graph_part3.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-3">
                    <div className="sale-info">Marketing</div>
                    <strong>10%</strong>
                  </div>
                </div>
                <div className="graph-part part04">
                  <img
                    src={require("../Assets/compress/graph_part4.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-4">
                    <div className="sale-info">Seed round</div>
                    <strong>4%</strong>
                  </div>
                </div>
                <div className="graph-part part05">
                  <img
                    src={require("../Assets/compress/graph_part5.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-5">
                    <strong>2%</strong>
                    <div className="sale-info">Public round</div>
                  </div>
                </div>
                <div className="graph-part part06">
                  <img
                    src={require("../Assets/compress/graph_part6.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-6">
                    <div className="sale-info">Liquidity / Exchanges</div>
                    <strong>15%</strong>
                  </div>
                </div>
                <div className="graph-part part07">
                  <img
                    src={require("../Assets/compress/graph_part7.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-7">
                    <div className="sale-info">Staking rewards</div>
                    <strong>7%</strong>
                  </div>
                </div>
                <div className="graph-part part08">
                  <img
                    src={require("../Assets/compress/graph_part8.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-8">
                    <strong>15%</strong>
                    <div className="sale-info">Treasury</div>
                  </div>
                </div>
                <div className="graph-part part09">
                  <img
                    src={require("../Assets/compress/graph_part9.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-9">
                    <div className="sale-info">Private round</div>
                    <strong>2%</strong>
                  </div>
                </div>

                <div className="graph-part part10">
                  <img
                    src={require("../Assets/compress/graph_part10.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-10">
                    <strong>18%</strong>
                    <div className="sale-info">Team</div>
                  </div>
                </div>
                <div className="graph-part part11">
                  <img
                    src={require("../Assets/compress/graph_part11.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-11">
                    <div className="sale-info">Advisors and Partners</div>
                    <strong>1%</strong>
                  </div>
                </div>
                <div className="graph-part part12">
                  <img
                    src={require("../Assets/compress/graph_part9.webp")}
                    className="graph_part_img"
                    alt="graph"
                  />
                  <div class="graph-text-info info-12">
                    <div className="sale-info">Ecosystem Rewards</div>
                    <strong>3%</strong>
                  </div>
                </div>
              </div>
              <div className="coin coin1">
                <img
                  src={require("../Assets/compress/coin1.webp")}
                  alt="coin"
                />
              </div>
              <div className="coin coin2">
                <img
                  src={require("../Assets/compress/coin2.webp")}
                  alt="coin"
                />
              </div>
              <div className="coin coin3">
                <img
                  src={require("../Assets/compress/coin3.webp")}
                  alt="coin"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Roadmap Section */}
        <section className="zurumi_roadmap_section" id="roadmap_zurumi">
          <div className="container">
            <div className="roadmap-content" data-aos="fade-up">
              <h2>Explore Our Roadmap</h2>
              <p>
                Follow our strategic milestones and witness the evolution of
                ZURUMI.
              </p>
            </div>

            <Slider {...settings}>
              <div data-aos="fade-left" data-aos-duration="2000">
                <img
                  src={require("../Assets/compress/roadmap_main_asset_two.webp")}
                  className="roadmap_asset_img"
                  alt="roadmap"
                  loading="lazy"
                />
              </div>

              <div
                className="phase"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <h4>PHASE 1</h4>
                <h3>Conceptualization & Research</h3>
                <p>
                  Initial market analysis, team formation, and
                  <br /> whitepaper creation.
                </p>
              </div>
              <div
                className="phase phase2"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <h4>PHASE 2</h4>
                <h3>Development & Initial Launch</h3>
                <p>
                  Smart contract development, website
                  <br /> launch, and private sale.
                </p>
              </div>
              <div
                className="phase phase3"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <h4>PHASE 3</h4>
                <h3>Public Sale & Ecosystem Expansion</h3>
                <p>
                  ICO launch, strategic partnerships, and
                  <br /> token exchange listing.
                </p>
              </div>
              <div
                className="phase phase4"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <h4>PHASE 4</h4>
                <h3>Ecosystem Development & Utility</h3>
                <p>
                  Launch of dApps, staking programs, and
                  <br /> community governance.
                </p>
              </div>
              <div
                className="phase phase5"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <h4>PHASE 5</h4>
                <h3>Global Expansion & Innovation</h3>
                <p>
                  Global outreach, cross-chain
                  <br /> interoperability, and continuous
                  <br /> ecosystem growth.
                </p>
              </div>
            </Slider>

            <div className="phase-bar">
              {" "}
              <PrevArrow
                currentSlide={currentSlide}
                onClick={() => sliderRef.current.slickPrev()}
              />
              <div className="phase-text-wrapper">
                {phases.map((phase, index) => (
                  <div
                    key={index}
                    className={`phase-item ${
                      index === currentSlide ? "active" : ""
                    }`}
                    onClick={() => handlePhaseClick(index)}
                  >
                    {phase}
                  </div>
                ))}{" "}
              </div>
              <NextArrow
                currentSlide={currentSlide}
                onClick={() => sliderRef.current.slickNext()}
              />
            </div>
          </div>
        </section>

        {/* Phases Section */}
        <section
          className="zurumi_phase_section"
          ref={sectionRef}
          id="presale_zurumi"
        >
          <div className="container">
            <div className="token_allocation_content">
              <h2 className="presale-title">Presale Phases</h2>
              <p className="presale-content">
                Secure Your Stake in the Future, Step by Step
              </p>
            </div>
            <div className="presale_main_asset">
              <img
                src={require("../Assets/compress/Presale_main.webp")}
                className="w-100"
                alt="presale"
              />
            </div>
            <div className="">
              <div className="presale_rectangle">
                <div className="rectangle rectangle1">
                  <img
                    src={require("../Assets/compress/Rectangle_1.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle2">
                  <div className="private_round_content">
                    <h3>2%</h3>
                    <h4>(1,500,000,000 ZRM)</h4>
                    <p>Private Round</p>
                  </div>
                  <div>
                    <img
                      src={require("../Assets/compress/Rectangle_2.webp")}
                      className="rectangle_img"
                      alt="rectangle"
                    />
                  </div>
                </div>
              </div>
              <div className="presale_rectanglee">
                <div className="rectangle rectangle3">
                  <img
                    src={require("../Assets/compress/Rectangle_3.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle4">
                  <div className="private_round_content">
                    <h3>2%</h3>
                    <h4>(1,500,000,000 ZRM)</h4>
                    <p>Public Round</p>
                  </div>
                  <img
                    src={require("../Assets/compress/Rectangle_4.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
              </div>
              <div className="presale_rectanglee">
                <div className="rectangle rectangle5">
                  <img
                    src={require("../Assets/compress/Rectangle_5.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle6">
                  <img
                    src={require("../Assets/compress/Rectangle_6.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle7">
                  <div className="private_round_content">
                    <h3>
                      $ 0.0150 per ZRM
                      <br /> <i class="ri-arrow-right-s-line"></i>$ 450,000
                    </h3>

                    <p>Private Round</p>
                  </div>
                  <img
                    src={require("../Assets/compress/Rectangle_7.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
              </div>
              <div className="presale_rectanglee">
                <div className="rectangle rectangle8">
                  <img
                    src={require("../Assets/compress/Rectangle_8.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle9">
                  <img
                    src={require("../Assets/compress/Rectangle_9.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
                <div className="rectangle rectangle10">
                  <div className="private_round_content">
                    <h3>
                      $ 0.0250 per ZRM <br />{" "}
                      <i class="ri-arrow-right-s-line"></i>$ 750,000
                    </h3>

                    <p>Public Round</p>
                  </div>

                  <img
                    src={require("../Assets/compress/Rectangle_10.webp")}
                    className="rectangle_img"
                    alt="rectangle"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Allocation table Section */}
        <section className="token_allocation_section">
          <div className="container">
            <div className="roadmap-content" data-aos="fade-up">
              <h2>ZURUMI Token Allocation</h2>
              <p>Optimized Allocation for Sustainable Growth</p>
            </div>
            <div className="table-responsive table-cont" data-aos="fade-up">
              <table className="table">
                <thead>
                  <tr className="stake-head">
                    <th>Allocations</th>
                    <th className="opt-nowrap">Percentage of tokens</th>
                    <th className="opt-nowrap">Token allocation</th>
                    <th className="opt-nowrap">Price per token</th>
                    <th className="opt-nowrap">Amount to raise</th>
                    <th className="opt-nowrap">Cliff period</th>
                    <th className="opt-nowrap">Total vesting period</th>
                    <th className="opt-nowrap">% Unlocked at TGE</th>
                    <th className="opt-nowrap"># Unlocked at TGE</th>
                    <th className="opt-nowrap">To be unlocked in vesting</th>
                  </tr>
                </thead>

                <tbody>
                  {assetList.map((options) => {
                    const {
                      id,
                      allocations,
                      tokenPercentage,
                      tokenAllocation,
                      tokenprice,
                      amountToRaise,
                      cliffPeriod,
                      vestingPeriod,
                      unlockPer,
                      unlockHash,
                      unlockVest,
                    } = options;

                    return (
                      <tr key={id} className="zurumi_table_data">
                        <td>
                          <div>
                            <h4 className="opt-name">{allocations}</h4>
                          </div>
                        </td>
                        <td>{tokenPercentage}</td>
                        <td>{tokenAllocation}</td>
                        <td>{tokenprice}</td>
                        <td>{amountToRaise}</td>
                        <td>{cliffPeriod}</td>
                        <td>{vestingPeriod}</td>
                        <td>{unlockPer}</td>
                        <td>{unlockHash}</td>
                        <td>{unlockVest}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <section className="zurumi_footer_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="footer_content">
                  <h3>
                    Let’s Make ZURUMI
                    <br /> Greater
                  </h3>
                  <a href="#buyzrm">
                    <button>Invest Now</button>
                  </a>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="zurumi_footer_links max-top-991px">
                  <div className="footer_links">
                    <h4>Quick links</h4>
                    <ul>
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#about_zurumi">About Zurumi</a>
                      </li>
                      <li>
                        <a href="#zurumi_tokenomics">Tokenomics</a>
                      </li>
                      <li>
                        <a href="#roadmap_zurumi">Roadmap</a>
                      </li>
                      <li>
                        {" "}
                        <a href="#presale_zurumi">Presale</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer_links">
                    <h4>Community</h4>
                    <div className="social">
                      <a href="">
                        <img
                          src={require("../Assets/compress/facebook_icon.webp")}
                          alt="facebook"
                          loading="lazy"
                        />
                      </a>
                      <a href="">
                        <img
                          src={require("../Assets/compress/twitter_icon.webp")}
                          alt="twitter"
                          loading="lazy"
                        />
                      </a>
                      <a href="">
                        <img
                          src={require("../Assets/compress/telegram_icon.webp")}
                          alt="telegram"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="zurumi_footer_links max-top-991px">
                  <div className="footer_links">
                    <h4>Download App</h4>
                    <div className="footer-scan-wrapper">
                      <img
                        src={require("../Assets/compress/footer-scan.png")}
                        alt="QR"
                        Loading="lazy"
                      />
                    </div>
                    <p className="footer-scan-qr-content mt-3">
                      Scan QR to download the app
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="zurumi_footer_links max-top-991px">
                  <div className="footer_links ">
                    <h4>Community</h4>
                    <div className="social">
                      <a
                        href="https://www.facebook.com/zurumi.exchange"
                        target="_blank"
                      >
                        <img
                          src={require("../Assets/compress/facebook.webp")}
                          alt="facebook"
                          loading="lazy"
                        />
                      </a>
                      <a href="https://x.com/zurumi_exchange" target="_blank">
                        <img
                          src={require("../Assets/compress/X.webp")}
                          alt="twitter"
                          loading="lazy"
                        />
                      </a>
                      <a href="https://t.me/+PY-habrUOhI1YjU0" target="_blank">
                        <img
                          src={require("../Assets/compress/telegram.webp")}
                          alt="telegram"
                          loading="lazy"
                        />
                      </a>
                      <a href="https://discord.gg/BUwqaxMVvW" target="_blank">
                        <img
                          src={require("../Assets/compress/discord.webp")}
                          alt="telegram"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://www.tiktok.com/@zurumi_cex"
                        target="_blank"
                      >
                        <img
                          src={require("../Assets/compress/tik-tok.webp")}
                          alt="telegram"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/zurumi_exchange/"
                        target="_blank"
                      >
                        <img
                          src={require("../Assets/compress/instagram.webp")}
                          alt="telegram"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="footer_copy_test">
          <img
            src={require("../Assets/compress/footer_border.webp")}
            className="footer_border"
            alt="footer-border"
          />
          2024 ZURUMI. All rights reserved.
        </div>
      </main>
    </>
  );
};

export default Landing;
